<template>
  <!--   <div
    class="
      head-bar-ab
      z-100
      bg-green
      d-flex
      justify-content-around
      align-items-center
      w-100
    "
  > -->
  <div
    :class="`${
      $route.query.single || $route.query.listing ? 'head-bar-ab' : 'head-bar'
    } z-100 bg-green d-flex justify-content-around align-items-center w-100`"
  >
    <h1
      v-if="$route.query.single"
      class="font-rem-1-5 m-auto brown-white-regular"
    >
      {{ $route.query.serial }}
    </h1>
    <h1 v-else class="font-rem-1-5 m-auto brown-white-regular">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    title() {
      const boxesOrHome = this.$route.path == "/" ? "home" : "boxes"
      const code = this.$route.params.view
        ? this.$route.params.view
        : boxesOrHome
      let rta = ""
      switch (code) {
        case "profile":
          rta = "Perfil"
          break
        case "notifications":
          rta = "Notificaciones"
          break
        case "calendar":
          rta = "Calendario"
          break
        case "boxes":
          rta = "Zona Midbox"
          break
        case "home":
          rta = "Proximamente"
          break

        default:
          rta = "Zona Midbox"
          break
      }
      return rta
    }
  }
}
</script>

<style></style>
